import { Link } from "gatsby"
import React, { useState } from "react"

function Alternator({ header, copy, image, align, svg }) {
  return (
    <div
      className={`my-36 md:my-60 px-6 mx-auto max-w-6xl flex-wrap flex items-center justify-center lg:justify-between ${
        align === "right" ? "flex-row-reverse" : "flex-row"
      }`}
    >
      <div className={`basis-full md:basis-6/12 mb-8 md:mb-0 ${
        align === "right" ? "md:ml-8 lg:ml-0" : "md:mr-8 lg:mr-0"
      }`}>
        <h1 className="h2 mb-4">{header}</h1>
        <div
          dangerouslySetInnerHTML={{ __html: copy }}
          className="p-md"
        />
      </div>
      <div className="flex items-center justify-center mt-12 md:mt-0 md:h-80 lg:h-96 max-w-sm basis-full md:basis-3/12 lg:basis-2/5">
        {svg}
      </div>
    </div>
  )
}

export default Alternator
