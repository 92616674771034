import React from "react"

function PuzzlesIcon() {
  return (
    <svg width="325" height="75" viewBox="0 0 325 75" fill="none" >
    <rect x="3.5" y="3.5" width="68" height="68" fill="white" stroke="black" strokeWidth="7"/>
    <rect x="128.5" y="3.5" width="68" height="68" fill="white" stroke="black" strokeWidth="7"/>
    <line x1="75" y1="36.5" x2="125" y2="36.5" stroke="black" strokeWidth="7"/>
    <mask id="path-4-inside-1_18_93" fill="white">
    <path d="M250 37.5C250 16.7893 266.789 0 287.5 0V0C308.211 0 325 16.7893 325 37.5V37.5C325 58.2107 308.211 75 287.5 75V75C266.789 75 250 58.2107 250 37.5V37.5Z"/>
    </mask>
    <path d="M250 37.5C250 16.7893 266.789 0 287.5 0V0C308.211 0 325 16.7893 325 37.5V37.5C325 58.2107 308.211 75 287.5 75V75C266.789 75 250 58.2107 250 37.5V37.5Z" fill="#0EECBC"/>
    <path d="M287.5 68C270.655 68 257 54.3447 257 37.5H243C243 62.0767 262.923 82 287.5 82V68ZM318 37.5C318 54.3447 304.345 68 287.5 68V82C312.077 82 332 62.0767 332 37.5H318ZM287.5 7C304.345 7 318 20.6553 318 37.5H332C332 12.9233 312.077 -7 287.5 -7V7ZM287.5 -7C262.923 -7 243 12.9233 243 37.5H257C257 20.6553 270.655 7 287.5 7V-7Z" fill="black" mask="url(#path-4-inside-1_18_93)"/>
    <line x1="200" y1="36.5" x2="250" y2="36.5" stroke="black" strokeWidth="7"/>
    <path d="M33.45 43.5V42.3C33.45 40.6 33.7833 39.2 34.45 38.1C35.15 37 36.2667 36.0833 37.8 35.35L38.65 34.95C40.15 34.25 41.3 33.5 42.1 32.7C42.9 31.9 43.3 30.8333 43.3 29.5C43.3 28.5333 43.05 27.7167 42.55 27.05C42.0833 26.35 41.4167 25.8167 40.55 25.45C39.6833 25.0833 38.6667 24.9 37.5 24.9C36.3 24.9 35.2167 25.1 34.25 25.5C33.3167 25.9 32.5667 26.5 32 27.3C31.4667 28.1 31.2 29.1 31.2 30.3V31.4H24.7V30.4C24.7 28.1 25.2667 26.1167 26.4 24.45C27.5333 22.7833 29.0667 21.5167 31 20.65C32.9333 19.75 35.1 19.3 37.5 19.3C39.8667 19.3 41.9667 19.7333 43.8 20.6C45.6667 21.4667 47.1333 22.6667 48.2 24.2C49.2667 25.7333 49.8 27.5 49.8 29.5C49.8 31.5 49.4333 33.15 48.7 34.45C47.9667 35.7167 47.0333 36.75 45.9 37.55C44.8 38.3167 43.6667 38.9833 42.5 39.55L41.65 39.95C41.05 40.2167 40.6333 40.55 40.4 40.95C40.1667 41.3167 40.05 41.8667 40.05 42.6V43.5H33.45ZM36.95 55.7C35.6167 55.7 34.4833 55.2667 33.55 54.4C32.65 53.5 32.2 52.35 32.2 50.95C32.2 49.55 32.65 48.4167 33.55 47.55C34.4833 46.65 35.6167 46.2 36.95 46.2C38.3167 46.2 39.45 46.65 40.35 47.55C41.25 48.4167 41.7 49.55 41.7 50.95C41.7 52.35 41.25 53.5 40.35 54.4C39.45 55.2667 38.3167 55.7 36.95 55.7Z" fill="black"/>
    <path d="M158.45 43.5V42.3C158.45 40.6 158.783 39.2 159.45 38.1C160.15 37 161.267 36.0833 162.8 35.35L163.65 34.95C165.15 34.25 166.3 33.5 167.1 32.7C167.9 31.9 168.3 30.8333 168.3 29.5C168.3 28.5333 168.05 27.7167 167.55 27.05C167.083 26.35 166.417 25.8167 165.55 25.45C164.683 25.0833 163.667 24.9 162.5 24.9C161.3 24.9 160.217 25.1 159.25 25.5C158.317 25.9 157.567 26.5 157 27.3C156.467 28.1 156.2 29.1 156.2 30.3V31.4H149.7V30.4C149.7 28.1 150.267 26.1167 151.4 24.45C152.533 22.7833 154.067 21.5167 156 20.65C157.933 19.75 160.1 19.3 162.5 19.3C164.867 19.3 166.967 19.7333 168.8 20.6C170.667 21.4667 172.133 22.6667 173.2 24.2C174.267 25.7333 174.8 27.5 174.8 29.5C174.8 31.5 174.433 33.15 173.7 34.45C172.967 35.7167 172.033 36.75 170.9 37.55C169.8 38.3167 168.667 38.9833 167.5 39.55L166.65 39.95C166.05 40.2167 165.633 40.55 165.4 40.95C165.167 41.3167 165.05 41.8667 165.05 42.6V43.5H158.45ZM161.95 55.7C160.617 55.7 159.483 55.2667 158.55 54.4C157.65 53.5 157.2 52.35 157.2 50.95C157.2 49.55 157.65 48.4167 158.55 47.55C159.483 46.65 160.617 46.2 161.95 46.2C163.317 46.2 164.45 46.65 165.35 47.55C166.25 48.4167 166.7 49.55 166.7 50.95C166.7 52.35 166.25 53.5 165.35 54.4C164.45 55.2667 163.317 55.7 161.95 55.7Z" fill="black"/>
    </svg>
  )
}

export default PuzzlesIcon;
