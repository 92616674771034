import React from "react"

function GoodsIcon() {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6"
        y="6"
        width="138"
        height="138"
        fill="white"
        stroke="black"
        strokeWidth="12"
      />
      <path
        d="M44.2 87.7V62.3H43.3L40 74.3H33.7V73.7L37.9 59H50.8V87.7H59.5V94H34.3V87.7H44.2ZM80.1652 89.95C80.1652 90.6167 80.0319 91.25 79.7652 91.85C79.5319 92.4167 79.1986 92.9167 78.7652 93.35C78.3319 93.75 77.8152 94.0667 77.2152 94.3C76.6486 94.5667 76.0486 94.7 75.4152 94.7C74.7486 94.7 74.1319 94.5667 73.5652 94.3C72.9986 94.0667 72.4986 93.75 72.0652 93.35C71.6319 92.9167 71.2819 92.4167 71.0152 91.85C70.7819 91.25 70.6652 90.6167 70.6652 89.95C70.6652 89.2833 70.7819 88.6667 71.0152 88.1C71.2819 87.5 71.6319 87 72.0652 86.6C72.4986 86.1667 72.9986 85.8333 73.5652 85.6C74.1319 85.3333 74.7486 85.2 75.4152 85.2C76.0486 85.2 76.6486 85.3333 77.2152 85.6C77.8152 85.8333 78.3319 86.1667 78.7652 86.6C79.1986 87 79.5319 87.5 79.7652 88.1C80.0319 88.6667 80.1652 89.2833 80.1652 89.95ZM80.1652 73.25C80.1652 73.9167 80.0319 74.55 79.7652 75.15C79.5319 75.7167 79.1986 76.2167 78.7652 76.65C78.3319 77.05 77.8152 77.3833 77.2152 77.65C76.6486 77.8833 76.0486 78 75.4152 78C74.7486 78 74.1319 77.8833 73.5652 77.65C72.9986 77.3833 72.4986 77.05 72.0652 76.65C71.6319 76.2167 71.2819 75.7167 71.0152 75.15C70.7819 74.55 70.6652 73.9167 70.6652 73.25C70.6652 72.5833 70.7819 71.9667 71.0152 71.4C71.2819 70.8 71.6319 70.3 72.0652 69.9C72.4986 69.4667 72.9986 69.1333 73.5652 68.9C74.1319 68.6333 74.7486 68.5 75.4152 68.5C76.0486 68.5 76.6486 68.6333 77.2152 68.9C77.8152 69.1333 78.3319 69.4667 78.7652 69.9C79.1986 70.3 79.5319 70.8 79.7652 71.4C80.0319 71.9667 80.1652 72.5833 80.1652 73.25ZM102.43 87.7V62.3H101.53L98.2305 74.3H91.9305V73.7L96.1305 59H109.03V87.7H117.73V94H92.5305V87.7H102.43Z"
        fill="black"
      />
    </svg>
  )
}

export default GoodsIcon
