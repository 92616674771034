import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

function Hero({ header, copy, ctaText, ctaLink, ctaText2, ctaLink2 }) {
  return (
    <div className="my-24 md:my-32 lg:my-36 px-6">
      <h1 className="h1 max-w-2xl mx-auto text-center mb-6">{header}</h1>
      <p className="p-lg max-w-3xl mx-auto text-center text-slate-800">
        {copy}
      </p>
      <div className="flex items-center justify-center">
        {ctaLink && (
          <a className="btn-primary mx-2 my-8" href={ctaLink}>
            {ctaText}
          </a>
        )}

        {ctaLink2 && (
          <a target="_blank" className="btn-primary mx-2 my-8" href={ctaLink2}>
            {ctaText2}
          </a>
        )}
      </div>
    </div>
  )
}

export default Hero
