import React from "react"

function TokensIcon() {
  return (
    <svg width="207" height="210" viewBox="0 0 207 210" fill="none">
      <circle
        cx="37.5537"
        cy="150.825"
        r="34.0537"
        fill="#0EECBC"
        stroke="black"
        strokeWidth="7"
      />
      <path
        d="M64.4384 52.3069C64.4384 69.2887 53.3018 82.337 37.0908 82.337C20.8798 82.337 9.74316 69.2887 9.74316 52.3069C9.74316 42.904 14.6967 35.3578 21.3381 27.3882C22.5882 25.888 23.9103 24.3632 25.2603 22.806C29.383 18.051 33.767 12.9945 37.165 7.41589C38.793 10.0214 40.626 12.5672 42.5256 15.0508C44.3241 17.4023 46.3414 19.8943 48.3233 22.3426C49.8687 24.2515 51.3925 26.1339 52.7744 27.9024C59.5208 36.5367 64.4384 44.3846 64.4384 52.3069Z"
        fill="#2ED3F9"
        stroke="black"
        strokeWidth="7"
      />
      <path
        d="M122.234 182.196L157.987 120.271L193.739 182.196H122.234Z"
        fill="#FE5D03"
        stroke="black"
        strokeWidth="7"
      />
      <rect
        x="123.906"
        y="8.8515"
        width="68.1074"
        height="68.1074"
        fill="#E6E7F0"
        stroke="black"
        strokeWidth="7"
      />
    </svg>
  )
}

export default TokensIcon
