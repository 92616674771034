import { Link } from "gatsby"
import React, { useState } from "react"

function Faq({ question, answer }) {
  const [open, setOpen] = useState(false)

  return (
    <div className={`my-12 px-6 mx-auto max-w-3xl`}>
      <div className="">

          <span
            className={`cursor-pointer h3 mb-3 flex items-start transition-all py-2`}
            onClick={() => setOpen(!open)}
          >
          <svg className={`inline mr-4 mt-2 transition-all min-w-[14px] ${open ? 'rotate-180' : 'rotate-90'}`} width="14" height="12" viewBox="0 0 14 12" fill="none">
            <path d="M7 0L13.9282 12H0.0717969L7 0Z" fill="black"/>
          </svg>
            <span>
            {question}
            </span>
          </span>
        <div
          dangerouslySetInnerHTML={{ __html: answer }}
          className={`p-md overflow-hidden transition-all ${
            open ? "opacity-100 max-h-full" : "max-h-0 opacity-0"
          }`}
        />
      </div>
    </div>
  )
}

export default Faq
