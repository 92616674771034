import { Link } from "gatsby"
import React, { useState } from "react"

function Team({ members, header, copy }) {
  return (
    <div
      className={`mt-24 mb-16 md:mt-60 md:mb-48 px-6 mx-auto max-w-6xl flex items-start justify-between flex-wrap`}
    >
      <div className="basis-full mb-16 max-w-2xl">
        <h1 className="h2 mb-4">{header}</h1>
        <p className="p-md">{copy}</p>
        <p className="tooltip p-md">
          Or Email.
          <span className="tooltiptext">info@godmode.world</span>
        </p>
      </div>
      <div className="flex items-start justify-between flex-wrap basis-full">
        {members.map((member, i) => {
          return (
            <div
              key={`member-${i}`}
              className="basis-3/5 mx-auto md:mx-0 mb-12 md:basis-1/4"
            >
              <div className="h-32 md:h-48 w-36 mx-auto  md:w-full bg-black mb-4"></div>
              <p className="font-sans text-xl text-center md:text-left mb-2">
                {member.name}
              </p>
              <p className="font-mono text-md text-black text-center md:text-left">
                <a target="_blank" href={member.link}>
                  LinkedIn
                </a>
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Team
