import React from "react"

function GoalIcon() {
  return (
    <svg
      width="256"
      height="250"
      viewBox="0 0 256 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.5"
        y="3.5"
        width="68.1074"
        height="68.1074"
        fill="white"
        stroke="black"
        strokeWidth="7"
      />
      <rect
        x="93.5"
        y="3.5"
        width="68.1074"
        height="68.1074"
        fill="#0EECBC"
        stroke="black"
        strokeWidth="7"
      />
      <rect
        x="183.5"
        y="3.5"
        width="68.1074"
        height="68.1074"
        fill="white"
        stroke="black"
        strokeWidth="7"
      />
      <rect
        x="183.5"
        y="90.5"
        width="68.1074"
        height="68.1074"
        fill="#FE5D03"
        stroke="black"
        strokeWidth="7"
      />
      <rect
        x="93.5"
        y="90.5"
        width="68.1074"
        height="68.1074"
        fill="#E6E7F0"
        stroke="black"
        strokeWidth="7"
      />
      <rect
        x="3.5"
        y="90.5"
        width="68.1074"
        height="68.1074"
        fill="white"
        stroke="black"
        strokeWidth="7"
      />
      <rect
        x="3.5"
        y="177.5"
        width="68.1074"
        height="68.1074"
        fill="#2ED3F9"
        stroke="black"
        strokeWidth="7"
      />
      <rect
        x="93.5"
        y="177.5"
        width="68.1074"
        height="68.1074"
        fill="white"
        stroke="black"
        strokeWidth="7"
      />
      <rect
        x="183.5"
        y="177.5"
        width="68.1074"
        height="68.1074"
        fill="black"
        stroke="black"
        strokeWidth="7"
      />
      <path
        d="M34.45 43.5V42.3C34.45 40.6 34.7833 39.2 35.45 38.1C36.15 37 37.2667 36.0833 38.8 35.35L39.65 34.95C41.15 34.25 42.3 33.5 43.1 32.7C43.9 31.9 44.3 30.8333 44.3 29.5C44.3 28.5333 44.05 27.7167 43.55 27.05C43.0833 26.35 42.4167 25.8167 41.55 25.45C40.6833 25.0833 39.6667 24.9 38.5 24.9C37.3 24.9 36.2167 25.1 35.25 25.5C34.3167 25.9 33.5667 26.5 33 27.3C32.4667 28.1 32.2 29.1 32.2 30.3V31.4H25.7V30.4C25.7 28.1 26.2667 26.1167 27.4 24.45C28.5333 22.7833 30.0667 21.5167 32 20.65C33.9333 19.75 36.1 19.3 38.5 19.3C40.8667 19.3 42.9667 19.7333 44.8 20.6C46.6667 21.4667 48.1333 22.6667 49.2 24.2C50.2667 25.7333 50.8 27.5 50.8 29.5C50.8 31.5 50.4333 33.15 49.7 34.45C48.9667 35.7167 48.0333 36.75 46.9 37.55C45.8 38.3167 44.6667 38.9833 43.5 39.55L42.65 39.95C42.05 40.2167 41.6333 40.55 41.4 40.95C41.1667 41.3167 41.05 41.8667 41.05 42.6V43.5H34.45ZM37.95 55.7C36.6167 55.7 35.4833 55.2667 34.55 54.4C33.65 53.5 33.2 52.35 33.2 50.95C33.2 49.55 33.65 48.4167 34.55 47.55C35.4833 46.65 36.6167 46.2 37.95 46.2C39.3167 46.2 40.45 46.65 41.35 47.55C42.25 48.4167 42.7 49.55 42.7 50.95C42.7 52.35 42.25 53.5 41.35 54.4C40.45 55.2667 39.3167 55.7 37.95 55.7Z"
        fill="black"
      />
      <path
        d="M124.45 217.5V216.3C124.45 214.6 124.783 213.2 125.45 212.1C126.15 211 127.267 210.083 128.8 209.35L129.65 208.95C131.15 208.25 132.3 207.5 133.1 206.7C133.9 205.9 134.3 204.833 134.3 203.5C134.3 202.533 134.05 201.717 133.55 201.05C133.083 200.35 132.417 199.817 131.55 199.45C130.683 199.083 129.667 198.9 128.5 198.9C127.3 198.9 126.217 199.1 125.25 199.5C124.317 199.9 123.567 200.5 123 201.3C122.467 202.1 122.2 203.1 122.2 204.3V205.4H115.7V204.4C115.7 202.1 116.267 200.117 117.4 198.45C118.533 196.783 120.067 195.517 122 194.65C123.933 193.75 126.1 193.3 128.5 193.3C130.867 193.3 132.967 193.733 134.8 194.6C136.667 195.467 138.133 196.667 139.2 198.2C140.267 199.733 140.8 201.5 140.8 203.5C140.8 205.5 140.433 207.15 139.7 208.45C138.967 209.717 138.033 210.75 136.9 211.55C135.8 212.317 134.667 212.983 133.5 213.55L132.65 213.95C132.05 214.217 131.633 214.55 131.4 214.95C131.167 215.317 131.05 215.867 131.05 216.6V217.5H124.45ZM127.95 229.7C126.617 229.7 125.483 229.267 124.55 228.4C123.65 227.5 123.2 226.35 123.2 224.95C123.2 223.55 123.65 222.417 124.55 221.55C125.483 220.65 126.617 220.2 127.95 220.2C129.317 220.2 130.45 220.65 131.35 221.55C132.25 222.417 132.7 223.55 132.7 224.95C132.7 226.35 132.25 227.5 131.35 228.4C130.45 229.267 129.317 229.7 127.95 229.7Z"
        fill="black"
      />
      <path
        d="M214.45 43.5V42.3C214.45 40.6 214.783 39.2 215.45 38.1C216.15 37 217.267 36.0833 218.8 35.35L219.65 34.95C221.15 34.25 222.3 33.5 223.1 32.7C223.9 31.9 224.3 30.8333 224.3 29.5C224.3 28.5333 224.05 27.7167 223.55 27.05C223.083 26.35 222.417 25.8167 221.55 25.45C220.683 25.0833 219.667 24.9 218.5 24.9C217.3 24.9 216.217 25.1 215.25 25.5C214.317 25.9 213.567 26.5 213 27.3C212.467 28.1 212.2 29.1 212.2 30.3V31.4H205.7V30.4C205.7 28.1 206.267 26.1167 207.4 24.45C208.533 22.7833 210.067 21.5167 212 20.65C213.933 19.75 216.1 19.3 218.5 19.3C220.867 19.3 222.967 19.7333 224.8 20.6C226.667 21.4667 228.133 22.6667 229.2 24.2C230.267 25.7333 230.8 27.5 230.8 29.5C230.8 31.5 230.433 33.15 229.7 34.45C228.967 35.7167 228.033 36.75 226.9 37.55C225.8 38.3167 224.667 38.9833 223.5 39.55L222.65 39.95C222.05 40.2167 221.633 40.55 221.4 40.95C221.167 41.3167 221.05 41.8667 221.05 42.6V43.5H214.45ZM217.95 55.7C216.617 55.7 215.483 55.2667 214.55 54.4C213.65 53.5 213.2 52.35 213.2 50.95C213.2 49.55 213.65 48.4167 214.55 47.55C215.483 46.65 216.617 46.2 217.95 46.2C219.317 46.2 220.45 46.65 221.35 47.55C222.25 48.4167 222.7 49.55 222.7 50.95C222.7 52.35 222.25 53.5 221.35 54.4C220.45 55.2667 219.317 55.7 217.95 55.7Z"
        fill="black"
      />
      <path
        d="M34.45 130.5V129.3C34.45 127.6 34.7833 126.2 35.45 125.1C36.15 124 37.2667 123.083 38.8 122.35L39.65 121.95C41.15 121.25 42.3 120.5 43.1 119.7C43.9 118.9 44.3 117.833 44.3 116.5C44.3 115.533 44.05 114.717 43.55 114.05C43.0833 113.35 42.4167 112.817 41.55 112.45C40.6833 112.083 39.6667 111.9 38.5 111.9C37.3 111.9 36.2167 112.1 35.25 112.5C34.3167 112.9 33.5667 113.5 33 114.3C32.4667 115.1 32.2 116.1 32.2 117.3V118.4H25.7V117.4C25.7 115.1 26.2667 113.117 27.4 111.45C28.5333 109.783 30.0667 108.517 32 107.65C33.9333 106.75 36.1 106.3 38.5 106.3C40.8667 106.3 42.9667 106.733 44.8 107.6C46.6667 108.467 48.1333 109.667 49.2 111.2C50.2667 112.733 50.8 114.5 50.8 116.5C50.8 118.5 50.4333 120.15 49.7 121.45C48.9667 122.717 48.0333 123.75 46.9 124.55C45.8 125.317 44.6667 125.983 43.5 126.55L42.65 126.95C42.05 127.217 41.6333 127.55 41.4 127.95C41.1667 128.317 41.05 128.867 41.05 129.6V130.5H34.45ZM37.95 142.7C36.6167 142.7 35.4833 142.267 34.55 141.4C33.65 140.5 33.2 139.35 33.2 137.95C33.2 136.55 33.65 135.417 34.55 134.55C35.4833 133.65 36.6167 133.2 37.95 133.2C39.3167 133.2 40.45 133.65 41.35 134.55C42.25 135.417 42.7 136.55 42.7 137.95C42.7 139.35 42.25 140.5 41.35 141.4C40.45 142.267 39.3167 142.7 37.95 142.7Z"
        fill="black"
      />
    </svg>
  )
}

export default GoalIcon
