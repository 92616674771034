import { Link } from "gatsby"
import React, { useState } from "react"

function Community({ header, copy, ctaLink, ctaText, ctaLink2, ctaText2 }) {

  return (
    <div className={`w-full bg-black`}>
      <div className='text-white max-w-5xl mx-auto py-16 md:py-24 px-6'>
        <h1 className='h2 mb-4 text-center'>{header}</h1>
        <p className='p-lg text-center'>{copy}</p>
        <div className='flex items-center justify-center mt-8'>
        {ctaLink && (
          <a target='_blank' className='btn-secondary mr-8' href={ctaLink} onClick={()=>window.fathom.trackGoal('5WRDIMK8', 0)}>{ctaText}</a>
        )}

        {ctaLink2 && (
          <a  target='_blank' className='btn-secondary mr-8' href={ctaLink2}>{ctaText2}</a>
        )}
        </div>
      </div>
    </div>
  )
}

export default Community
