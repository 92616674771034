import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Alternator from "../components/alternator"
import Faq from "../components/faq"
import Team from "../components/team"
import Community from "../components/community"
import PuzzlesIcon from "../components/icons/puzzles"
import TokensIcon from "../components/icons/tokens"
import GoodsIcon from "../components/icons/goods"
import ComburnIcon from "../components/icons/comburn"
import GoalIcon from "../components/icons/goal"
import SeasonsIcon from "../components/icons/seasons"
import WallIcon from "../components/icons/wall"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Welcome to godMode" />
    <Hero
      header={"Welcome to godMode"}
      copy={
        "Part collaborative experience, part puzzle game, with a whole lotta weird. Gain secret knowledge and earn 1:1 NFTs by solving puzzles to recreate the world from the beginning of time."
      }
      ctaLink={"/launch-signup"}
      ctaText={"Sign Up"}
    />

    <Alternator
      header={"The Puzzles"}
      copy={
        "Not a jigsaw. More like a + b = c, where all you know is c.<br><br>Use tokens to solve puzzles which unlock new tokens that can solve more puzzles."
      }
      svg={<PuzzlesIcon />}
    />

    <Alternator
      align={"right"}
      header={"The Tokens"}
      copy={
        "<strike>All</strike> Most token types represent IRL people, places, and things. Some are naughty, some are nice.<br><br>Every player starts with the same four starting token types – Air, Earth, Fire, & Water – to solve the first set of puzzles via the comburn.<br><br>"
      }
      svg={<TokensIcon />}
    />

    <Alternator
      header={"The Comburn"}
      copy={
        "Puzzles can only be solved via the comburn. Complete a comburn by combining two tokens to try to create a new token type. <br/><br/>Most comburns won't create new tokens, some will.<br><br>But beware - regardless of it's outcome, both input tokens will be burned with every comburn. Combine and burn, baby!<br><br><strong>**Violent witches cackle**</strong>"
      }
      svg={<ComburnIcon />}
    />

    <Alternator
      align={"right"}
      header={"The Goal"}
      copy={
        "Every player exists in the same game world, so the goal of godMode is to be the first to solve as many of the puzzles as possible to receive uber-rare 1:1 tokens. <br><br>Some answers will be logical, and others will be downright silly."
      }
      svg={<GoalIcon />}
    />

    <Alternator
      header={"The Goods"}
      copy={
        "A successful comburn will always result in the minting of a new token that can be used to solve additional puzzles.<br><br>If you are the first to successfully comburn that token type (solve that puzzle), you will receive a special 1:1 token and another token for more comburns.<br><br>You now also know the <i>secret</i>. Will you share your secrets or sell them to the highest bidder?"
      }
      svg={<GoodsIcon />}
    />

    <Alternator
      align={"right"}
      header={"The Seasons"}
      copy={
        "New puzzles and token types will be released in 7 seasons. Each season will contain 100+ new puzzles and begin around every 21 days.<br><br>The only thing players will know is what new token types are available. No other hints or helpers, probably."
      }
      svg={<SeasonsIcon />}
    />

    <Alternator
      header={"The Wall"}
      copy={
        "Along with getting a fancy-ass 1:1 token, if you are the first to solve a puzzle, your eth address will be displayed on the wall."
      }
      svg={<WallIcon />}
    />
    <h1
      className="h2 pt-8 px-6 mx-auto max-w-6xl mb-16 md:mb-24 text-center"
      id="faq"
    >
      Real Talk
    </h1>
    <Faq
      question={"What is the objective of the game?"}
      answer={
        "The main objective is to solve as many of the 700+ puzzles as you can. Every player exists in the same game world and plays at the same time. <br><br>Being the first to solve puzzles will yield unique benefits and 1:1 NFTs.<br><br>The puzzle pattern is simple: token a + token b = token c. The only information the players will know is the value of token c. You have to use the comburn and figure out which tokens equal token c.<br><br>To solve the puzzles, you will need some logic, some luck, and a whole lot of courage (especially in the later seasons)."
      }
    />

    <Faq
      question={"How does it work?"}
      answer={
        "All players will start with the same four starting token types that can be minted at any point during the game. Those token types are Air, Earth, Fire, and Water. Additionally, all players are competing against each other, trying to solve the same puzzles at the same time.<br><br>As players solve the puzzles, they will earn new token types that can be used to solve more puzzles.<br><br>Token types represent real-life items, things, or even people – like flowers, animals, and Bob Marley… or flamethrowers, amino acids, and punk6529 (we told you it was weird). The core pattern of the puzzle is to combine two component tokens in order to discover the secret and earn a new token type. <br><br>However, with <strong>each attempt to discover a new token type, the two component tokens will be burned and removed from play forever</strong>. We’re calling this mechanism the comburn. Each time you attempt to comburn two tokens, whether it successfully solves the puzzle or not, the two component tokens will be burned."
      }
    />

    <Faq
      question={"What is a comburn?"}
      answer={
        "We define comburn as the action to combine two tokens, to create one single new token, while simultaneously burning the component tokens."
      }
    />

    <Faq
      question={"What do we mint?"}
      answer={
        "The only thing that any player can mint without the comburn is a sack for 1 MATIC. Each sack contains the exact same thing:<br><br><strong>20 tokens in total</strong><ul><li>5 Air tokens</li><li>5 Earth tokens</li><li>5 Fire tokens</li><li>5 Water tokens</li></ul><br>These tokens will be used in the comburn to solve the first set of puzzles and unlock new token types that can be used for subsequent puzzles."
      }
    />

    <Faq
      question={
        "What happens when a player successfully solves a puzzle for the first time?"
      }
      answer={
        "When a player successfully solves a puzzle for the first time (no other player has successfully comburned two tokens to create the token type before), it will trigger two actions.<br><br>    1. You will receive two new tokens. The first will be a 1:1 NFT with visual indications and metadata showing that it is a special token. This 1:1 token is meant to live on as part of a collection, so it will not be able to be used in comburn attempts. The other token you receive will be the standard version of that token type used in gameplay. It will have the standard branding/markings and can be entered into other comburns.<br><br>2. The wallet address will be added to the Wall for that token type."
      }
    />

    <Faq
      question={"Token Distribution? Are there seasons? How long is the game?"}
      answer={
        "Yes there will be seasons. In total, there will be 700+ token types to discover. The game will be played in 7 distinct time segments or in-game seasons called 'Days'. Each Day will release ~100 new puzzles/token types to be comburned and discovered. New Days are expected to drop every two or three weeks."
      }
    />

    <Faq
      question={"How do you protect against the outsized influence of whales?"}
      answer={
        "One of our guiding principles in designing this game was to make it accessible to as many people as possible, from as many socio-economic backgrounds as possible. We designed the starting conditions of the game to make it so that each player starts at the same point with access to the same four token types.<br><br>There is no limit to the number of sacks that can be minted, and therefore whales cannot horde supply.<br><br>However, there is little to be done to protect against massive buying power in the secondary market that could grant advantages. For example, if a newly discovered token type is selling on the secondary market for a high price, whales could buy that supply and then be among the first to gain access to that token and include it in comburn attempts for other unsolved token types. Other players may not be able to afford to buy it on the secondary market, and then have to acquire that newly discovered token via many comburn attempts and guesses of their own."
      }
    />

    <Faq
      question={"Are there plans for other games or future development?"}
      answer={
        "Yes, there absolutely are plans and designs for continued development. The timeline for those plans will be directly influenced by the success of this game. We are exploring options in infinite games vs finite games.<br><br>Additionally, we are especially interested in allowing players to add their own personalizations to well created aglorithms and protocols that maximize self-expression and creative potential for all players."
      }
    />

    <Faq
      question={"How is godMode different from other web3 games?"}
      answer={
        "In most web3 games, the model is called play-to-earn, which means the more you play the game, the more in-game currency you can earn. godMode is different and isn’t about gaining a specific cryptocurrency or utility token. The goal is to be the first to solve the puzzles and earn 1:1 NFTs. There is no native utility token/currency in godMode."
      }
    />

    <Faq
      question={"Am I allowed to work with other players to solve the puzzles?"}
      answer={
        "A unique aspect of godMode is that it’s a real-time collaborative experience. Every player exists in the same game world and is racing to solve these puzzles.<br><br> Some players will go it alone and some will join up and share resources. This part is exciting because it'll be very interesting to see the group knowledge sharing and coordination dynamics play out over the course of the game. How the community will organize in an attempt to solve the puzzles is something we are eager to see.<br><br>Therefore, there are no rules for organizing teams, sharing information, building second-layer tools, etc. We expect a lot of variations. In godMode, instead of liquidity pools, we envision knowledge pools being used to solve the late game puzzles."
      }
    />

    <Faq
      question={"Will there be a secondary market?"}
      answer={
        "Absolutely. To obtain tokens, players can either mint a sack to receive 5 each of the Air, Earth, Fire, and Water token types. Then they can comburn tokens in order to mint additional tokens.<br><br><strong>OR</strong> players can purchase specific tokens on secondary markets.<br><br>Also, the 1:1 tokens that are rewarded for solving puzzles first can be traded like any other NFT collection."
      }
    />

    <Faq
      question={"Any royalty fees?"}
      answer={
        "We have decided to institute a 5% royalty fee on secondary market trades. To mint a sack and get 20 tokens to play the game, the fee is 1 MATIC. While that's not free, but we believe it is more than affordable for the large majority of prospective players. Most of the revenue from minting will cover our overhead costs to run and maintain the game.<br><br>The royalty fees will be more substantial and allow us to fund other projects, experiences, and games. "
      }
    />

    <Faq
      question={"What kind of art/imagery will be shown on the tokens?"}
      answer={
        "All imagery and art on the tokens will be generatively created using a number of cutting-edge AI/ML models and techniques. That being said, each token of a specific token type will have the same art."
      }
    />

    <Faq
      question={"What are the benefits of hodling the 1:1 tokens?"}
      answer={
        "This is an interesting question and one we've thought a lot about. Ultimately that’s up to each individual to decide based on their experience with the game, personal preferences, and risk tolerance. That being said, this is a good place to expand a bit more on this topic.<br><br>The 1:1 tokens are meant to be commemorative tokens and cannot be used in a comburn. However, like all NFTs, they can be traded and sold. For collectors, the decision to hold, and what value to set on a given token, often comes down to a number of factors including utility, rarity, and tangibility.<br><br> For utility, the 1:1 tokens will only have utility going forward in future projects we build (and any community derivates that are created). Unfortunately, we don't have any set plans to share at this moment, but we can promise the 1:1 token will unlock benefits in future projects.<br><br>As for rarity, the 1:1s are by definition one of a kind as they can only be created once and only if you are the first to solve a specific puzzle. <br><br>Finally, and transparently, there are no benefits with regards to tangibility with the 1:1 tokens."
      }
    />

    <Faq
      question={"What about community participation and derivatives?"}
      answer={
        "We set out to build a finite game to test a number of ideas and assumptions. Additionally, we knew that if we were to build a finite game then we wanted to build a sort of base layer for further community-driven creation and exploration. We are doing our best to make our design choices as open as possible to provide the community with fewer boundaries in order to create and build on top of the godMode base layer."
      }
    />

    <Faq
      question={"What would make godMode successful in the eyes of the team?"}
      answer={
        "Success for us is having any number of players eventually solve all the 700+ puzzles, without any game breaking errors or smart contract exploits. This would mean that there is a core group of passionate players who genuinely enjoy the game, whom we can engage with and cheer on from the sidelines."
      }
    />

    <Faq
      question={"How can I join the community?"}
      answer={
        "Everyone is welcome to join the community. Please follow our Twitter page and sign up for the closed-launch. We will get your discord name from that sign up form and add you to the discord once it is opened. FYI - The initial launch will not be open to everyone as there is a cap of about 50 people."
      }
    />

    <Faq
      question={"How will the launch work?"}
      answer={
        "It will be a closed launch with a small number of players (~50) getting access at launch day. There are seven seasons in the game, called Days. Day 1 will have daily puzzle drops at random times and will take place over 35 'real life' days. Over those 35 days, we will drop ~100 puzzles.<br><br>Days (seasons) 2 - 7 will likely not have daily drops (that's up to the community to decide). They'll have a one time drop of 100+ puzzles. Once we're comfortable with the beta progress, we will allow the initial players to invite friends to participate.<br><br> On the start of Day 2 anyone can play and will have full access. That is also when we will drop the next set of 100 puzzles for that season.<br><br>We know that may not sound ideal for everyone, since some will be left out of the Day 1 season. But we promise it's for the good of the game and to ensure we can ramp up successfully and ensure a great experience for everyone."
      }
    />

    <Faq
      question={"Wen launch?"}
      answer={"It's our intention to launch on July 7, 2023."}
    />

    <Faq
      question={"Why are you launching on Polygon?"}
      answer={
        "Our desire is to build experiences that are accessible to as many people as possible. Accessibility includes technical know-how and cost of entry.<br><br>godMode is a high transaction game and therefore we needed to use an alternative to Ethereum that supports lower transaction fees.<br><br>Polygon meets all of our requirements and then some. Polygon utilizes incredible technology and has a vibrant ecosystem of builders/creatives, games, and DeFi solutions. We are proud to be building godMode on Polygon."
      }
    />

    <Faq
      question={"Is there anything else you'd like the community to know?"}
      answer={
        "We agree with the premise that in general NFT collection prices as a whole should be derived from ongoing utility and value creation by the creator. This is less true for NFTs that are genuinely “just for the art” but absolutely applies to tokens in the godMode collection.<br><br>There is one thing all prospective players of godMode should know - it is first and foremost a game. We say that because we hope people decide to get involved because they want to experience and be a part of an original genre web3 game.<br><br>Euphoria is ripe in the NFT ecosystem and unfortunately, for many folks token re-sale price has become the most important factor or benefit of joining a community and holding tokens. But for a game, what happens to token prices when the game is effectively over?<br><br> We have ideas and intentions about what shape “ongoing utility and value creation” means for godMode 1:1 token holders for future projects we plan to develop. However, our primary goal right now is to focus on building a fun and challenging game for you to explore, play, and enjoy.<br><br>Our humble hope is that this experience is the first of many projects we develop and launch successfully."
      }
    />

    <Team
      header={"Team"}
      copy={
        "We've chosen to doxx ourselves and share our real identities. This is because we want you to know we're here for the long haul and want to build connections with the community that decides to play the game. We're excited to bring this experience to you all and we certainly hope you enjoy it. Feel free to reach out to us on LinkedIn or the Discord channel once you're there!"
      }
      members={[
        {
          name: "Luke Blackburn",
          link: "https://www.linkedin.com/in/blackburnluke/",
        },
        {
          name: "Brandon Jewell",
          link: "https://www.linkedin.com/in/roycejewell/ ",
        },
      ]}
    />

    <Community
      header={"@godmodeworld"}
      copy={
        "Follow on Twitter to get all the up-to-date information on launch details and token drops."
      }
      ctaLink={"https://twitter.com/godmodeworld"}
      ctaText={"godMode Twitter"}
    />
  </Layout>
)

export default IndexPage
